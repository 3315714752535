import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-886f6444"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "form-box"
};
import { getUserExperience, updateUserExperience } from '@/api/user_experience';
import { ref } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
  __name: 'user_experience_config',
  setup(__props) {
    const formRef1 = ref(null);
    const rules1 = ref({
      subscriptionAddress: [{
        required: true,
        message: '请输入体验天数',
        trigger: 'blur'
      }]
    });
    const form1 = ref({
      subscriptionAddress: ''
    });
    const formData1 = ref({
      user_experience: ''
    });
    const confirmModification1 = async () => {
      formRef1.value.validate(async valid => {
        if (valid) {
          ElMessageBox.confirm('是否确认修改体验天数?', '警告', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const {
              subscriptionAddress
            } = form1.value;
            formData1.value.user_experience = subscriptionAddress;
            try {
              await updateUserExperience(formData1.value);
            } catch (error) {
              console.error('Failed to update ', error);
            }
            ElMessage({
              type: 'success',
              message: '修改成功'
            });
          }).finally(async () => {
            await initUserExperience();
          });
        }
      });
    };
    const initUserExperience = async () => {
      const response = await getUserExperience();
      form1.value.subscriptionAddress = response.UserExperience;
    };
    initUserExperience();
    return (_ctx, _cache) => {
      const _component_el_input_number = _resolveComponent("el-input-number");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_form, {
        ref_key: "formRef1",
        ref: formRef1,
        model: form1.value,
        rules: rules1.value,
        "label-width": "130px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "体验天数(天):",
          prop: "subscriptionAddress"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input_number, {
            modelValue: form1.value.subscriptionAddress,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form1.value.subscriptionAddress = $event),
            min: 0,
            max: 9999
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            onClick: confirmModification1
          }, {
            default: _withCtx(() => [_createTextVNode("确认修改")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model", "rules"])])]);
    };
  }
};