import request from '@/api/request'

export const getUserExperience = (params) => {
  return request({
    url: 'config/getUserExperience',
    params
  })
}

export const updateUserExperience = (params) => {
  return request({
    url: 'config/updateUserExperience',
    method: 'POST',
    params
  })
}
